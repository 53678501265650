<template>
    <div class="page page-green">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('身份验证')" left-arrow @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper result-wrapper" v-if="isOver">
            <template v-if="active == 9">
                <div class="au-panel">
                    <div class="au-panel_bd">
                        <div class="result-box">
                            <div class="result-icon">
                                <img src="../../assets/pictures/verified.png" alt="">
                            </div>
                            <div class="result-label">
                                {{$t('恭喜您，您已完成了认证')}}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="active == 10">
                <div class="au-panel">
                    <div class="au-panel_bd">
                        <div class="result-box">
                            <div class="result-icon">
                                <img src="../../assets/pictures/wating.png" alt="">
                            </div>
                            <div class="result-label">
                                {{$t('您的KYC认证已提交，请您耐心等候')}}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="wrapper" v-else>
            <div class="step-top">
                <van-steps :active="active" active-icon="checked" finish-icon="checked" active-color="#52c9ea">
                    <van-step>
                        <span class="dot">1</span>
                    </van-step>
                    <van-step>
                        <span class="dot">2</span>
                    </van-step>
                    <van-step>
                        <span class="dot">3</span>
                    </van-step>
                    <van-step>
                        <span class="dot">4</span>
                    </van-step>
                </van-steps>
            </div>
            <template v-if="active == -1">
                <div class="au-panel" >
                    <div class="au-panel_hd">{{$t('个人信息')}}</div>
                    <div class="au-panel_bd">
                        <van-form class="au-form" >
                            <van-row gutter="16">
                                <van-col span="24">
                                    <van-field type="text" v-model="country" :placeholder="$t('请输入国籍')" :label="$t('国籍')"  >
                                    </van-field>
                                </van-col>
                                <van-col span="24">
                                    <van-field type="text" v-model="name" :placeholder="$t('请输入姓名')" :label="$t('姓名')" />
                                </van-col>
                                <van-col span="24">
                                    <van-field type="text" @click="cateShow = true" readonly  v-model="surname" :placeholder="$t('请选择证件类型')" :label="$t('证件类型')" >
                                    <template #button>
                                        <van-icon name="arrow-down" />
                                    </template>
                                    </van-field>
                                </van-col>
                                <van-col span="24">
                                    <van-field type="text" v-model="birthday" :placeholder="$t('请输入和证件上相同的证件号')" :label="$t('证件号码')" >
                                    </van-field>
                                </van-col>
                            </van-row>
                        </van-form>
                    </div>
                </div>
                <div class="au-btn-fixed">
                    <template>
                        <van-button block type="primary" @click="getFirst">{{$t('继续')}}</van-button>
                    </template>
                </div>
            </template>
            <template v-if="active == 0">
                <div class="au-panel">
                    <div class="au-panel_hd">{{$t('身份验证')}}</div>
                    <div class="au-panel_bd">
                        <div class="result-box">
                            <div class="result-step">
                                <img src="../../assets/pictures/ver-step-2.png" alt="">
                            </div>
                            <div class="result-label">
                                {{$t('身份验证')}}
                            </div>
                        </div>
                        <div class="tips-cell">
                            <div class="tip-label">{{$t('Tips')}}:</div>
                            <div class="tip-text" v-if="this.$t('aut_tip_1') != 'aut_tip_1'">
                                {{$t('aut_tip_1')}}
                            </div>
                            <div class="tip-text" v-if="this.$t('aut_tip_2') != 'aut_tip_2'">
                                {{$t('aut_tip_2')}}
                            </div>
                            <div class="tip-text" v-if="this.$t('aut_tip_3') != 'aut_tip_3'">
                                {{$t('aut_tip_3')}}
                            </div>
                            <div class="tip-text" v-if="this.$t('aut_tip_4') != 'aut_tip_4'">
                                {{$t('aut_tip_4')}}
                            </div>
                            <div class="tip-text" v-if="this.$t('aut_tip_5') != 'aut_tip_5'">
                                {{$t('aut_tip_5')}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="au-btn-fixed">
                    <van-button block type="primary"  @click="goNext">{{$t('继续')}}</van-button>
                    <van-button block type="gray" @click="active = -1">{{$t('返回上一步')}}</van-button>
                </div>
            </template>
            <template v-if="active == 1">
                <div class="au-panel" >
                    <div class="au-panel_hd">{{$t('证件信息')}}</div>
                    <div class="au-panel_bd">
                        <van-form class="au-form" >
                            <van-row gutter="16">
                                    <van-col span="24">
                                        <div class="au-upload">
                                        <div class="au-label">
                                            {{ $t('证件正面') }}
                                        </div>
                                        <van-uploader accept='.jpg,.png' preview-size="160px" v-model="front_picList" deletable :max-count="1" :after-read="frontRead" @delete="delete1">
                                            <div class="upload-img">
                                                <div class="img-box">
                                                    <img src="../../assets/pictures/front_pic.png" class="img">
                                                </div>
                                            </div>
                                            </van-uploader>
                                        </div>
                                    </van-col>
                                    <van-col span="24">
                                        <div class="au-upload">
                                        <div class="au-label">
                                            {{ $t('证件反面') }}
                                        </div>
                                        <van-uploader accept='.jpg,.png' preview-size="160px" v-model="obverse_picList" deletable :max-count="1" :after-read="obverseRead" @delete="delete2">
                                            <div class="upload-img">
                                                <div class="img-box">
                                                    <img src="../../assets/pictures/obverse_pic.png" class="img">
                                                </div>
                                            </div>
                                        </van-uploader>
                                        </div>
                                    </van-col>
                            </van-row>
                        </van-form>
                    </div>
                </div>
                <div class="au-btn-fixed">
                    <van-button block type="primary"  @click="goNext2">{{$t('继续')}}</van-button>
                    <van-button block type="gray" @click="active = 0">{{$t('返回上一步')}}</van-button>
                </div>
            </template>
            <template v-if="active == 2">
                <div class="au-panel" >
                    <div class="au-panel_hd">{{$t('证件信息')}}</div>
                    <div class="au-panel_bd">
                        <van-form class="au-form" >
                            <van-row gutter="16">
                                <van-col span="24" >
                                    <div class="au-upload">
                                        <div class="au-label">
                                            {{ $t('手持证件照') }}
                                        </div>
                                        <div class="upload-box">
                                            <van-uploader accept='.jpg,.png' preview-size="160px" v-model="handheld_picList" deletable :max-count="1" :after-read="handheldRead" @delete="delete3">
                                                <div class="upload-img">
                                                    <div class="img-box">
                                                        <img src="../../assets/pictures/handheld_pic.png" class="img">
                                                    </div>
                                                </div>
                                            </van-uploader>
                                        </div>
                                    </div>
                                </van-col>
                            </van-row>
                        </van-form>
                    </div>
                </div>
                <div class="au-btn-fixed">
                    <van-button block type="primary"  @click="comfirm">{{$t('提交审核')}}</van-button>
                    <van-button block type="gray" @click="active = 1">{{$t('返回上一步')}}</van-button>
                </div>
            </template>
            <template v-if="active == 3">
                <div class="au-panel">
                    <div class="au-panel_bd">
                        <div class="result-box">
                            <div class="result-icon">
                                <img src="../../assets/pictures/goout.png" alt="">
                            </div>
                            <div class="result-label">
                                {{$t('您的身份验证已提交审核')}}
                            </div>
                            <div class="result-desc">
                                {{$t('预计完成时间需要2-3个工作日')}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="au-btn-fixed">
                    <van-button block type="gray"  @click="reset">{{$t('返回首页')}}</van-button>
                </div>
            </template>
        </div>
        
        <van-popup v-model="cateShow" round position="bottom" >
            <van-picker
            :title="$t('证件类型')"
            show-toolbar
            :confirm-button-text="$t('确定')"
            :cancel-button-text="$t('取消')"
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
            />
        </van-popup>
        <van-popup v-model="birthdayShow" round position="bottom" >
            <van-datetime-picker
            v-model="currentDate"
            type="date"
            :confirm-button-text="$t('确定')"
            :cancel-button-text="$t('取消')"
            :title="$t('出生日期')"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="confirmB"
            @cancel="cancelB"
            />
        </van-popup>
    </div>
</template>

<script>
    var showLoading = null; //定义toast的变量
    export default {
        components: {},
        data() {
            return {
                cateShow: false,
                columns: [this.$t('身份证'), this.$t('护照'), this.$t('驾照')],
                birthdayShow: false,
                country: '',
                name: '',
                surname: '',
                birthday: '',
                handheld_picList:[],
                front_picList:[],
                obverse_picList:[],
                front_pic: '',
                obverse_pic: '',
                handheld_pic: '',
                minDate: new Date(1940, 0, 1),
                maxDate: new Date(2023, 10, 1),
                currentDate: new Date(),
                loading: false,
                langs: [],
                select_lang_zh_cn: false,
                localeCur:0,
                active:-1,
                isnext:false,
                isOver:false
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            cancelB(){
                this.birthdayShow = false
            },
            confirmB(val){
                console.log(val)
                let year = val.getFullYear()//年
                let month = val.getMonth() + 1//月
                let day = val.getDate()//日
                let hour = val.getHours()//时
                let minute = val.getMinutes()//分
                if (month >= 1 && month <= 9) { month = `0${month}` }
                if (day >= 1 && day <= 9) { day = `0${day}` }
                if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
                if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
                // this.user_birthday = `${year}-${month}-${day} ${hour}:${minute}`
                this.birthday = `${year}-${month}-${day}`
                this.birthdayShow = false
            },
            getData() {
                this.loading = true;
                this.$axios
                    .get(this.$api_url.get_langs)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.langs = res.data.data;
                            this.langs = this.langs.filter((item) => {
                                if (!(this.select_lang_zh_cn > 0)) {
                                    return item.lang != 'zh-cn' && item.lang != 'zh_cn' && item.lang != 'cn';
                                }
                                return true;
                            });
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(err.message);
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },
            getIdentity() {
                this.$axios
                    .get(this.$api_url.get_identity_auth)
                    .then((res) => {
                        if (res.data.code == 0) {
                            if(res.data.data.info){
                                this.country = res.data.data.info.country;
                                this.name = res.data.data.info.name;
                                this.surname = res.data.data.info.surname;
                                this.birthday = res.data.data.info.birthday;
                                this.front_pic = res.data.data.info.front_pic;
                                this.handheld_pic = res.data.data.info.handheld_pic;
                                this.obverse_pic = res.data.data.info.obverse_pic;

                                const authParams = this.$route.query.is_auth_set;
                                if(authParams == 2){
                                    this.handheld_picList = []
                                    this.front_picList = []
                                    this.obverse_picList = []
                                    this.front_pic = ""
                                    this.handheld_pic = ""
                                    this.obverse_pic = ""
                                }else{
                                    this.handheld_picList.push({
                                        url:res.data.data.info.handheld_pic
                                    })
                                    this.front_picList.push({
                                        url:res.data.data.info.front_pic
                                    })
                                    this.obverse_picList.push({
                                        url:res.data.data.info.obverse_pic
                                    })
                                }
                            }
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(err.message);
                    })
                    .then(() => {
                    });
            },
            changeLocale(lang,index) {
                this.country = lang.title;
                this.localeCur = index
                this.countryShow = false;
            },
            reset(){
                this.$router.push({path: '/my',});
            },
            
            frontRead(file, ext) {
                showLoading = this.$toast.loading({
                    forbidClick: true,
                    duration:'0'
                });
                this.front_pic = file.file;
                const formData = new FormData();
                formData.append("file", this.front_pic);
                this.$axios.post('/upload', formData, {
                    headers: {"Content-Type": "multipart/form-data"},
                }).then((res) => {
                    if(res.data.code == 0){
                        showLoading.close();
                        this.front_pic = res.data.data
                        this.$toast.success(res.data.msg);
                        console.log(res.data)
                    }
                }).catch((e) => {
                    this.$toast.fail(e.data.msg);
                    showLoading.close();
                })
            },
            obverseRead(file, ext) {
                showLoading = this.$toast.loading({
                    forbidClick: true,
                    duration:'0'
                });
                this.obverse_pic = file.file;
                const formData = new FormData();
                formData.append("file", this.obverse_pic);
                this.$axios.post('/upload', formData, {
                    headers: {"Content-Type": "multipart/form-data"},
                }).then((res) => {
                    if(res.data.code == 0){
                        console.log(res.data)
                        this.obverse_pic = res.data.data
                        showLoading.close();
                        this.$toast.success(res.data.msg);
                    }
                }).catch((e) => {
                    this.$toast.fail(e.data.msg);
                    showLoading.close();
                })
            },
            handheldRead(file, ext) {
                showLoading = this.$toast.loading({
                    forbidClick: true,
                    duration:'0'
                });
                this.handheld_pic = file.file;
                const formData = new FormData();
                formData.append("file", this.handheld_pic);
                this.$axios.post('/upload', formData, {
                    headers: {"Content-Type": "multipart/form-data"},
                }).then((res) => {
                    if(res.data.code == 0){
                        console.log(res.data)
                        this.handheld_pic = res.data.data
                        showLoading.close();
                        this.$toast.success(res.data.msg);
                    }
                }).catch((e) => {
                    this.$toast.fail(e.data.msg);
                    showLoading.close();
                })
            },
            getFirst() {
                if(this.country == ''){
                    this.$toast.fail(this.$t('请输入国籍'));
                    return;
                }
                if(this.name == ''){
                    this.$toast.fail(this.$t('请输入姓名'));
                    return;
                }
                if(this.surname == ''){
                    this.$toast.fail(this.$t('请选择证件号'));
                    return;
                }
                if(this.birthday == ''){
                    this.$toast.fail(this.$t('请输入证件号'));
                    return;
                }
                this.active = 0
            },
            goNext(){
                this.active = 1
            },

            delete1(){
                this.front_pic = ''
            },
            delete2(){
                this.obverse_pic = ''
            },
            delete3(){
                this.handheld_pic = ''
            },
            goNext2(){
                if(this.front_pic == ''){
                    this.$toast.fail(this.$t('请上传证件正面照'));
                    return;
                }
                if(this.obverse_pic == ''){
                    this.$toast.fail(this.$t('请上传证件反面照'));
                    return;
                }
                this.active = 2
            },
            comfirm() {
                if(this.handheld_pic == ''){
                    this.$toast.fail(this.$t('请上传手持照片'));
                    return;
                }
                this.$axios.post(this.$api_url.send_identity_auth,
                    {
                        country: this.country, 
                        name: this.name, 
                        surname: this.surname, 
                        birthday: this.birthday, 
                        handheld_pic: this.handheld_pic, 
                        front_pic: this.front_pic, 
                        obverse_pic: this.obverse_pic, 
                    }
                ).then((res) => {
                    this.$toast.success(res.data.msg);
                    this.active = 3
                }).catch((e) => {
                    this.$toast.fail(e.data.msg);
                })

                
            },
            onConfirm(value, index) {
                this.surname = value;
                this.cateShow = false
                // Toast(`当前值：${value}, 当前索引：${index}`);
            },
            onCancel() {
                // Toast('取消');
                this.cateShow = false
            },
        },
        mounted() {
            this.getData()
            this.getIdentity()
            this.select_lang_zh_cn = localStorage.getItem('app.select_lang_zh_cn') || 1;

            const authParams = this.$route.query.is_auth_set;
            if(authParams == 0){
                this.active = 10
                this.isOver = true
            }
            if(authParams == 1){
                this.active = 9
                this.isOver = true
            }
        },
        created() {
        }

    }
</script>
<style>
    .au-panel_hd{
        background-color: #394d58;
        color: #ffffff;
    }
    .au-panel_bd{
        padding: 10px 0;
    }
    .au-panel{
        padding: 15px;
        background: #394d58;
        margin-bottom: 10px;
        border-radius: 10px;
        overflow: hidden;
        font-size: 14px;
        box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    }
    .au-panel .van-cell{
        display: block;
        padding: 10px 0;
        background-color: #394d58;
    }
    .au-panel .van-field__label{
        width: 100%;
        margin-bottom: 5px;
        color: #ffffff;
    }
    .au-panel .van-field__body{
        background-color: #2b3640;
        border-radius: 8px;
        padding: 10px;
    }
    .country-panel{
        padding:15px 10px;
    }
    .country-panel .van-cell{
        background-color: transparent;
        color: #ffffff;
    }
    .result-box{
        text-align: center;
        padding: 40px 0;
    }
    .result-icon{
        margin-bottom: 20px;
    }
    .result-icon img{
        width: 120px;
    }
    .result-label{
        font-size: 16px;
        margin-bottom: 10px;
    }
    .result-desc{
        opacity: .7;
    }
    .au-upload,
    .tips-cell{
        padding: 10px 0;
    }
    .tips-cell{
        line-height: 1.67;
    }
    .au-label{
        margin-bottom: 10px;
    }
    .tip-text{
        opacity: .7;
    }
    .step-top{
        padding: 0 10%;
        margin-bottom: 30px;
    }
    .step-top .van-steps{
        background-color: transparent;
        overflow:visible;
        min-height: 40px;
    }
    .van-step--horizontal .van-step__circle-container{
        background-color: #131d26;
        padding: 0 2px;
        margin-top: -15px;
    }
    .van-step__circle{
        display: none;
    }
    .au-form .van-field__control{
        color: #ffffff;
    }
    .step-top .dot{
        width: 36px;
        height: 36px;
        color: #ffffff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #394d58;
        border-radius: 50%;
        border: 2px solid #52c9ea;
        font-size: 16px;
    }
    .van-step--horizontal .van-step__title{
        position: relative;
        z-index: 22;
        margin-left: 0;
    }
    .van-step--horizontal:last-child{
        right: -10px;
    }
    .van-step--horizontal:first-child{
        left: -10px;
    }
    .van-step--horizontal .van-step__line{
        height: 4px;
        top: 15px;
        background-color: #429ccf;
    }
    .van-step__icon--active{
        font-size: 36px!important;
    }
    .van-step--horizontal .van-step__title--active{
        display: none;
    }
    .van-step--horizontal .van-step__icon{
        font-size: 36px!important;
    }
    .van-step--finish .van-step__title{
        display: none;
    }
    .van-steps--horizontal .van-steps__items{
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .van-button--reset{
        border: 0;
        background-color: #dfdfdf;
    }
    .au-btn-fixed .van-button+.van-button{
        margin-top: 15px;
    }
    .au-btn-fixed{
        padding: 20px 15%;
    }
    .au-upload .van-uploader__upload{
        background-color: #2b3640;
        border-radius: 8px;
        width: calc(100vw - 60px)!important;
    }
    .au-upload .van-uploader__preview,
    .au-upload .van-uploader__preview-image{
        margin: 0;
        width: calc(100vw - 60px)!important;
    }
    .au-upload .van-uploader__upload img{
        border-radius: 8px;
    }
    .upload-img{
        background-color: #2b3640;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        height: 140px;
        margin: 10px;
        width: calc(100vw - 80px)!important;
    }
    .upload-img::before,
    .upload-img::after{
        content: '';
        height: 160px;
        width: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        top: 0;
    }
    .upload-img::before{
        left: 0;
        background-image: url(../../assets/pictures/img-left.png);
    }
    .upload-img::after{
        right: 0;
        background-image: url(../../assets/pictures/img-right.png);
    }

    .van-uploader__preview-delete{
        width: 20px;
        height: 20px;
        border-radius: 0 0 0 100%;
    }
    .van-uploader__preview-delete-icon{
        top: 1px;
        right: 1px;
    }
    .result-step{
        margin-bottom: 20px;
    }
    .result-step img{
        width: 160px;
    }
    .img-box{
        font-size: 0;
        padding: 10px 30px;
        border-radius: 8px;
        border: 1px solid #a3b6bd;
    }
    .result-wrapper .result-box{
        padding: 100px 0;
    }



</style>